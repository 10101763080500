import React from "react";

import { OfferCard } from "../components";

import css from "./style.module.scss";

import { v4 as uuidv4 } from "uuid"

import { render, exportAsPDF, exportAsBase64PDF } from "../../../utils"
import axios from "axios"

export const exportPDF = (offers, agent, data, uuid) => {

    const buildPDFPage = async (currentItem, currentPages = []) => {
        const page = Math.ceil(currentItem / 1) + 1
        const slice = currentItem < offers.length - 0 ? currentItem + 1 : currentItem + (offers.length - currentItem);
        const header = <div className={`${css.headerStyle}`}>
            <div>
                <img className={css.imageStyle} src={agent.image} />
            </div>
            <div style={{ display: "flex", flexDirection: "column", marginTop: "12px" }}>
                <div className={`${css.defaultStyle} ${css.nameStyle}`}>{agent.name}</div>
                <div className={`${css.defaultStyle} ${css.titleStyle}`}>{agent.title}</div>
                <div className={`${css.defaultStyle} ${css.underscoreStyle}`} />
                <div className={`${css.defaultStyle}`}>{agent.email}</div>
                <div className={`${css.defaultStyle}`}>{agent.website}</div>
                <div className={`${css.defaultStyle}`}>{agent.phone}</div>
            </div>
            <div style={{ flexGrow: 1 }} />
            <div className={css.qrCodeStyle}>
                <canvas className={"replace-with-qr-code"} />
            </div>
        </div>;
        const cards = offers.slice(currentItem, slice).map((card, index) => <OfferCard pdf={true} selected={false} key={uuidv4()} {...card} />)

        const footer = <div style={{ color: "black", padding: "2px 28px", display: "flex", justifyContent: "space-between", alignContent: "center", position: "absolute", bottom: "-0px", height: "36px", width: "595px" }}>
            <div>
                {`Results from ${new Date().toLocaleDateString()}`}
            </div>
            <div>
                {`Page ${page} of ${Math.ceil(offers.length / 1)}`}
            </div>
        </div>;


        const node = document.getElementById('pdf')


        await render(<div style={{ position: "fixed", left: "-2000px", width: "595px" }} id={"rendered-pdf"}>
            {
                <div key={uuidv4()} style={{ position: "relative", height: "842px", maxHeight: "842px", width: "595px", maxWidth: "595px" }}>
                    {header}
                    <div style={{ height: "520px" }}>
                        {cards}
                    </div>
                    {footer}
                </div>
            }
        </div>, node)


        const newPage = node.innerHTML.slice();
        await render(<div />, node)

        if (currentItem < offers.length - 1) {
            buildPDFPage(currentItem + 1, [...currentPages, newPage])
        } else {
            if (data) {
                console.log("blob")

                const resp = await axios({
                    method: "post",
                    url: "https://api3.myaspirequotes.qa.apolloquotes.com/v1/quotes/" + uuid,
                    data: { ...data, generated_doc: await exportAsBase64PDF([...currentPages, newPage]) },
                    headers: {
                        "Apollo-Quotes-Source": agent.id
                    }
                })


                console.log("res", resp)

            } else {
                exportAsPDF([...currentPages, newPage])
            }
        }
    }

    buildPDFPage(0);
}