import React, { useState, useEffect } from "react";
import { Accordeon, Container, Badge } from "../../.."
import css from "./style.module.scss";
import FilterGroup from "./filter-group";
import { ApolloCollors } from "../../../rules/design/apollo-colors";
import { v4 as uuid } from 'uuid';


const buildFilters = (items, label, props) => {

    return <>
        {
            items.map(item => {
                if (item.type === "GROUP") {
                    return <FilterGroup onChange={(i, v) => { props.onChange && props.onChange(label, i, v) }} key={label + item.type + item.attribute} {...item} />
                }
            })
        }
    </>
}

const FilterAccordeon = ({ activeTab, facetItem, key, props }) => {
    const [collapsed, setCollapsed] = useState(!(facetItem.label == "General" || facetItem.label == props.activeTab))

    useEffect(() => {
        setCollapsed(!(facetItem.label == "General" || facetItem.label == props.activeTab))
    },
        [activeTab]
    )

    return <Accordeon key={key} collapsed={collapsed} setCollapsed={setCollapsed} title={facetItem.label}>
        {
            buildFilters(facetItem.values, facetItem.label, props)
        }
    </Accordeon>
}




export default (props) => {





    return <Container className={css.searchFiltersContainer}>
        {props.data.map(facetItem => {
            if (facetItem.type === "GROUP") {
                return <FilterAccordeon props={props} key={"group" + facetItem.label} activeTab={props.activeTab} facetItem={facetItem} />
            }
        })}
    </Container>
}