import React, { useState } from "react"
import { Accordeon, FilePdfOutlined, Container, Icon, DownOutlined, UpOutlined } from "../../../../"
import css from "./style.module.scss"
import { Table } from "antd"

import { Button } from "antd"

const { Column, ColumnGroup } = Table;

const renderNetworkText = (text, record) => {

    if (text == "Not Covered") {
        return <Container style={{ fontSize: "12px", paddingLeft: "18px" }}>- -</Container>
    } else {
        const words = text.split(" ");
        return <Container className={`${css.a}`} style={{
            fontSize: words.length == 1 ? "18px" : "9px",
            paddingLeft: words.length == 1 ? "6px" : "0px"
        }}>
            {words.slice(0, 4).map((t, index) => <> {t}{index == 3 && words.length > 4 ? "..." : ""}  </>)}

        </Container>
    }
}

export default (props) => {
    if (props.benefits && props.benefits.length > 0) {

        const [benefitsVisible, setBenefitsVisible] = useState(props.pdf)

        let Legend = () => null;
        let Content = () => null;

        if (props.benefits[0].type == "BENEFIT_DETAILS") {
            Legend = () => benefitsVisible ? <Button className={css.bnButton} type="primary" onClick={() => {
                setBenefitsVisible(!benefitsVisible)
            }} > Show Less </Button> : <Button className={css.bnButton} type="primary" onClick={() => {
                setBenefitsVisible(!benefitsVisible)
            }} > <Icon style={{ width: "18px", height: "18px" }} icon="corn-check" fill="#fff" /> Benefits </Button>

            props.pdf && (Legend = () => null);

            if (benefitsVisible) {
                Content = () => <Table onHeaderRow={() => {
                    return {
                        className: css.header
                    }
                }
                }
                    onRow={(record, index) => {
                        return {
                            id: record.name
                        }
                    }
                    }
                    rowClassName={css.benefitsRow} dataSource={props.benefits.map(b => ({ ...b, key: b.name }))} pagination={false}
                    expandIconColumnIndex={4}
                    expandRowByClick={true}
                    expandIcon={({ expanded, onExpand, record }) =>
                        expanded ? (
                            <UpOutlined className={css.chevronUp} onClick={e => { onExpand(record, null) }} />
                        ) : (
                                <DownOutlined className={css.chevronDown} onClick={e => { onExpand(record, null) }} />
                            )
                    }


                    expandable={false && {
                        expandedRowRender: record =>
                            <Container className={css.expandedBenefit}>
                                <Container className={css.expandedBenefitBackground} />
                                <Container className={css.expandedBenefitRow}>
                                    <Container className={css.expandedBenefitLabel}>In Network:</Container>
                                    <Container className={css.expandedBenefitDescription}>{record.in_network}</Container>
                                </Container>
                                <Container className={css.expandedBenefitRow}>
                                    <Container className={css.expandedBenefitLabel}>Out of Network:</Container>
                                    <Container className={css.expandedBenefitDescription}>{record.out_of_network}</Container>
                                </Container>
                            </Container>
                    }}
                >

                    <Column width="55%" className={css.planNameColumn} dataIndex="name" key="name" title={() => <Container className={css.benefitsHeader}>Benefits:</Container>} render={(text, record) => <>
                        <Container className={css.planNameContainer}>
                            {!props.pdf && <Container className={css.planNameIcon}>
                                <svg className={css.cornCheck}>
                                    <use xlinkHref="/sprite.svg#corn-check" />
                                </svg>
                            </Container>}
                            <Container className={css.planNameText}>
                                {text}
                            </Container>
                        </Container>

                    </>} />
                    <ColumnGroup title="Network">
                        <Column onHeaderCell={() => {
                            return { className: css.inNetworkHeader }
                        }} className={css.planInNetworkColumn} title={() => <Container className={css.inNetworkHeader}>In</Container>} dataIndex="in_network" key="in_network" render={renderNetworkText} />
                        <Column className={css.planOutOfNetworkColumn} title={() => <Container className={css.outNetworkHeader}>Out</Container>} dataIndex="out_of_network" render={renderNetworkText} key="out_of_network" />
                    </ColumnGroup>
                </Table>

            }
        } else {

            Legend = () => <legend onClick={() => {
                setBenefitsVisible(!benefitsVisible)
            }} >Document {<FilePdfOutlined />}</legend>


        }


        return <div className={`${css.benefitsTable} ${props.pdf ? css.pdf : ""}`}>
            <Legend />
            <Content />

        </div>
    }
    else return null
}