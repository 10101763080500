import React from "react";
import { Badge, Checkbox, Container, ShareAltOutlined, Icon, Tooltip } from "../../..";
import { Skeleton } from "antd";

import BenefitsList from "./benefits-list"

import css from "./style.module.scss";
import { HeartFilled, HeartOutlined } from "../../../icon";

export const metalLevelColors = {
    Bronze: "#6A3805",
    Catastrophic: "#575988",
    Gold: "#AF9500",
    Platinum: "#3B3B41",
    Silver: "#B4B4B4"
}

export const OfferCardSkeleton = () => {
    return <Container className={css.offerCardContainer}>
        <Container style={{ borderColor: "#EEE", padding: "24px" }} className={css.infoContainer} >
            <Skeleton title={false} paragraph={{ rows: 4, width: "100%" }} active />
        </Container>
    </Container>
}

export default ({ benefits, metal_level, innerStyle, style, score, carrier, imgid, exportImage, pdf, onSelectChange, deductible, id, plan_name, premium, selected, info, showSelection }) => {

    return <Container style={style} id={imgid} className={`${css.offerCardContainer} ${pdf ? css.pdf : css.screen}`}>
        <Container style={innerStyle} className={`${css.infoContainer} ${pdf ? css.infoContainerScreenOnly : ""} ${score ? score > 50 ? css.green : score > 25 ? css.yellow : css.red : ""}`}>
            <Container className={css.planNames}>
                <Tooltip title={carrier.value} >
                    <Container className={css.issuerName}>
                        {carrier.value} {score ? `(score: ${"" + (100 - score)})` : ``}
                    </Container>
                </Tooltip>
                <Container className={css.planName}>
                    <Tooltip title={plan_name.value} >
                        {plan_name.value}
                    </Tooltip>
                </Container>
            </Container>

            <Container className={css.info2}>
                {info && info.label == "Metal Level" && <Icon icon="star-flag" fill={metalLevelColors[info.value]} />}
                {info && info.value == "NHICSupplemental" && < Icon icon="shield-filled" fill="#F7971C" />}
                {info && info.value == "STM" && <Icon icon="shield-outlined" fill="#F7971C" />}
                {info && info.value == "Basic" && <Icon icon="s-outlined" fill="#1B75BC" />}
                {info && info.value == "Crown" && <Icon icon="crown" fill="#1B75BC" />}
                {info && info.value == "Enhanced" && <Icon icon="s-filled" fill="#1B75BC" />}
                {info && info.value == "Short Term Health Insurance" && <Icon fill="#575988" icon="short-term" />}
                {info && info.value == "Dental Insurance" && <Icon icon="dental-insurance" fill="#053555" />}
                {info && info.value == "HealthiestYou" && <Icon icon="healthiest-you" fill="#77C3FF" />}
                {info && info.value == "Hospital Indemnity" && <Icon icon="hospital-indemnity" fill="#FF3D3D" />}
                {info && info.value == "Supplemental Indemnity" && <Icon icon="supplemental" fill="#F3BF38" />}
                {info && info.value == "HealthiestYou" && <Icon icon="healthiest-you" fill="#77C3FF" />}
                {info && info.value == "Term Life" && <Icon icon="term-life" fill="#1B75BC" />}
                {info && info.value == "Vision" && <Icon icon="vision" fill="#1C4C99" />}
                <Container className={`${css.planTypePrice} ${css.marginRight24px}`}>
                    <Container className={css.planType}>
                        {premium.label}
                    </Container>
                    <Container className={css.planPrice}>
                        $ {premium.value + " / Month"}
                        {
                            premium.premium_without_subsidy && <Container className={css.premiumWithoutSubsidy}>
                                $ {premium.premium_without_subsidy}
                            </Container>}


                    </Container>
                </Container>

            </Container>
            {
                pdf && <Container className={css.info3} >
                    <Container className={css.metalLevelText}>{info?.label ? info?.label + ": " + info?.value : ""}</Container>
                </Container>
            }
            <Container>
                <BenefitsList pdf={pdf} benefits={benefits} id={imgid} />
            </Container>
            {
                !pdf && <Container className={css.info3} >
                    {info ? <Container className={css.info3}>
                        {deductible.label}:
                        <Container className={css.metalLevelText}> ${deductible.value}</Container>
                    </Container > : <Container className={css.info3} ><Badge style={{ height: "18px", marginTop: "4px" }} color={"#fff"} /></Container>}

                </Container>
            }
            {!pdf && <Container onPress={() => {
                onSelectChange && onSelectChange({
                    id,
                    checked: !selected
                })
            }} className={`${css.info4} ${selected ? css.selected : ""}`}>
                Interested
                        {
                    selected ? <HeartFilled /> : <HeartOutlined />
                }
            </Container>}
        </Container>
    </Container>
}
