import React, { useState } from "react";

import DecisionSupport from "./decision-support";
import AboutPopup from "./decision-support/popups/about-popup"

import { Avatar, Badge, Button, Container } from "../../../"
import { FacebookFilled, ScheduleOutlined, PhoneOutlined } from "../../../"

import css from "./style.module.scss";




export default ({ data, mobileActiveContainer, onPicwellOn, onPicwellOff, enabled }) => {

    const [aboutPopupVisible, setAboutPopupVisible] = useState(false);


    const openAboutPopup = () => setAboutPopupVisible(true);
    const closeAboutPopup = () => setAboutPopupVisible(false);


    return <Container className={`${css.rightBarContainer} ${mobileActiveContainer !== "user-info" ? "hidden-on-mobile" : ""}`}>
        <Container className={css.avatarContainer}>
            <Avatar onPress={openAboutPopup} link="" round="true" src={data?.site?.siteMetadata?.agent?.imageSrc} />
            <Container onPress={openAboutPopup} className={css.aboutLink}>
                Bio
            </Container>
        </Container>
        {<Container className={`${css.contactSupportContainer}`}>
            <Container className={css.contactSupport}>
                <Container className={css.msg1} >Want to get an accurate quote?</Container>
                <Container className={css.msg2}>Let {<span onClick={openAboutPopup} >{data?.site?.siteMetadata?.agent?.name}</span>} help you</Container>
                <Container className={css.contactCard}>

                    <Container className={css.isOnline}>
                        <Container className={css.isOnlineText}>
                            {
                                data?.site?.siteMetadata?.agent?.phone
                            }
                        </Container>
                        <PhoneOutlined />

                    </Container>

                    <Container className={css.messageButtonsContainer}>
                        <a target="_blank" href={"https://m.me/ApolloInsuranceAdvisors"}>
                            <Button shape="round" className={css.sendMessageFacebookButton} >Send Message <Container className={css.sendMessageFacebookIcon}> <FacebookFilled /></Container> </Button>
                        </a>
                        {data?.site?.siteMetadata?.agent?.calendly && <a target="_blank" href={data?.site?.siteMetadata?.agent?.calendly}>
                            <Button shape="round" className={css.scheduleMeetingButton} > Schedule Call <Container className={css.scheduleMeetingIcon}> <ScheduleOutlined /> </Container> </Button>
                        </a>}
                        {/* <a target="_blank" href={ "https://wa.me/+55(31)984616723/?text=" + (`Hello, I would like to get a health insurance quote with you, for a person with ${props.age} years old and zip code ${props.zipCode}.`) }><Button shape="round" className={css.sendMessageWhatsappButton} >Send Message <Container className={css.sendMessageWhatsappIcon}><WhatsAppOutlined /></Container></Button></a> */}
                    </Container>
                </Container>
            </Container>
        </Container>}
        {<DecisionSupport enabled={enabled} onPicwellOn={onPicwellOn} onPicwellOff={onPicwellOff} onSubmit={onPicwellOn} />}
        <AboutPopup data={data} visible={aboutPopupVisible} onClose={closeAboutPopup} />

    </Container>
}