import React, { useState } from "react";

import css from "./style.module.scss";

import { Container, Slider, DollarTwoTone } from "../../"

export default (props) => {


    const marks = {};

    const [value, setValue] = useState([0, 0]);

    marks[props.min] = marks[props.max] = {
        label: `$${props.min}`,
        style: {
            transform: "translateX(20%)"
        }
    };

    marks[props.max] = {
        label: `$${props.max}`,
        style: {
            transform: "translateX(-110%)",
            whiteSpace: "nowrap"
        }
    };

    // TODO style
    return <Container style={{ ...props.label == "Premium" ? { marginBottom: "12px" } : {} }} className={`${css.items} `}>
        <Container className={css.priceRangeCardInfo}>
            <Container>{props.label}</Container>
            {
                (value[0] != props.min || value[1] != props.max) && <Container className={css.priceRangeFilters}>
                    <Container className={css.minMaxContainer}>
                        <Container className={css.min}>${value[0]} to ${value[1]}</Container>
                        {/* <Container className={css.max}> to: ${value[1]}</Container> */}
                    </Container>
                </Container>
            }
        </Container>
        <Container className={css.rangeSliderContainer}>
            {true && <DollarTwoTone className={css.rangeIcon} />}
            <Slider marks={marks} onChange={
                (v) => {
                    setValue(v)

                    props.onChange && props.onChange(v)
                }
            } value={value} tipFormatter={props.tipFormatter} min={props.min} max={props.max} className={css.rangeSlider} range />
        </Container>

    </Container>
}